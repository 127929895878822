<template>
  <div v-if="authUserIsAdmin">
    <LoadingMessage v-if="isLoading" :message="'Fetching items...'"></LoadingMessage>

    <ErrorMessage v-if="error" :error="error" class="my-3"></ErrorMessage>

    <form
      @submit.prevent
      v-if="!isLoading && !error"
      class="d-flex align-items-center bg-dark p-2 rounded"
    >
      <div class="form-floating-cta-container">
        <div class="h5 m-0 px-2 text-white">Files</div>
      </div>
      <IconButton
        @click="refresh"
        :icon="'sync-alt'"
        class="btn btn-primary ms-2"
      ></IconButton>
    </form>

    <div v-if="!isLoading && !error" class="rounded border bg-white mt-2">
      <div class="table-responsive">
        <table class="table m-0">
          <thead>
            <tr>
              <th scope="col" class="align-baseline">Name</th>
              <th scope="col" class="align-baseline"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!items.length">
              <td colspan="2">No files found</td>
            </tr>
            <AdminStorageManagerItem
              @delete="deleteItem($event)"
              v-for="(item, itemIdx) in items"
              :key="`key-${itemIdx}`"
              :videoUrl="item.url"
              :videoDate="item.lastModified"
            ></AdminStorageManagerItem>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { httpGet } from '@/core/http';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export default {
  name: 'AdminStorageManager',
  components: {
    IconButton: () => import('@/components/button/IconButton'),
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    AdminStorageManagerItem: () => import('@/components/admin/AdminStorageManagerItem'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsAdmin']),
  },
  methods: {
    refresh() {
      this.fetch();
    },
    async fetch() {
      this.isLoading = true;
      this.error = null;
      try {
        const res = await httpGet('/admin/storage');
        this.items = this.sortItems(res.data);
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    deleteItem(videoUrl) {
      const index = this.items.findIndex((item) => item === videoUrl);
      if (index > -1) this.items.splice(index, 1);
    },
    sortItems(items) {
      const arr = items.map((item) => {
        const p = item.lastModified.split(' ');
        const d = dayjs(`${p[2]}-${p[1]}-${p[3]} ${p[4]}`, 'DD-MMM-YYYY hh:mm:ss');
        return {
          ...item,
          timestamp: parseInt(d.format('x'), 10),
          lastModified: d.format('D MMMM YYYY hh:mm:ss'),
        };
      }).sort((a, b) => b.timestamp - a.timestamp);
      return arr;
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      items: [],
    };
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style lang="scss" scoped>
table thead tr th {
  width: 25%;

  &:first-child {
    width: 75%;
  }
}
</style>
